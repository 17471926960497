import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Layout,
  Modal,
  Row,
  Spin,
  Select,
} from "antd";

import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";

import {
  updateTag,
  listTagsFromParent,
  createTag,
  deleteTag,
  getTag,
  listParentTags,
} from "../services";
import { ITag, SupportedLanguage } from "../model";
import { ICreateTagDto } from "../model/dto//tag-dto";
import slugify from "slugify";
import { useNavigate, useParams } from "react-router-dom";
import LoadingLayout from "./shared/LoadingLayout";
import { languageOptions } from "~/utils/helpers";

const { confirm } = Modal;

export const TagEdition: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const tagId = params.id;
  const [innerTag, setInnerTag] = useState<ITag>();
  const [innerTagList, setInnerTagList] = useState<ITag[]>([]);
  const [innerTagListFromParent, setInnerTagListFromParent] = useState<ITag[]>(
    []
  );
  const [tagSaving, setTagSaving] = useState<boolean>(false);

  useEffect(() => {
    const fetchTag = async () => {
      const tagList = await listParentTags();
      if (tagList) setInnerTagList(tagList);

      if (tagId) {
        const tag = await getTag(tagId);

        if (tag) {
          const tagListFromParent = await listTagsFromParent(tag.uid);
          if (tagListFromParent) setInnerTagListFromParent(tagListFromParent);
        }

        setInnerTag(tag);
      }
    };
    fetchTag();
  }, [tagId]);

  const handleReturn = () => {
    navigate(-1);
  };

  const openNotificationSuccess = () => {
    notification["success"]({
      message: "Tag saved",
      description: "The tag was succesfully saved.",
    });
  };

  const handleEdition = async (values: ICreateTagDto) => {
    setTagSaving(true);

    if (tagId) {
      const updatedTag = await updateTag(tagId, {
        ...values,
        uid: tagId,
      });

      if (updatedTag) setInnerTag(updatedTag);

      navigate(`/reference-data/tag/${updatedTag.uid}`, { replace: true });
    } else {
      const createdTag = await createTag({
        ...values,
        uid: slugify(values.translations[SupportedLanguage.EN], {
          lower: true,
          strict: true,
        }),
      });
      if (createdTag) {
        navigate(`/reference-data/tag/${createdTag.uid}`, { replace: true });
      }
    }
    setTagSaving(false);
    openNotificationSuccess();
  };

  const handleDeletion = async () => {
    if (tagId)
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: <p>Are you sure to delete this article?</p>,
        async onOk() {
          const deletedArticle = await deleteTag(tagId);
          if (deletedArticle) {
            navigate("/reference-data/tag/");
          }
        },
        onCancel() {},
      });
  };

  if (!innerTag && params.id) return <LoadingLayout />;

  const initialTranslations = languageOptions.reduce(
    (acc, { value }) => {
      acc["translations"] = {
        ...(acc["translations"] || {}),
        [value]: innerTag?.translations[value] || "",
      };
      return acc;
    },
    { translations: {} }
  );

  return (
    <Layout>
      <Layout.Content style={{ padding: "50px" }}>
        <Row>
          <div>
            <Button
              onClick={handleReturn}
              shape='circle'
              style={{ marginRight: 20 }}
            >
              <LeftOutlined />
            </Button>
          </div>
          <h1 style={{ fontWeight: 800, fontSize: "30px" }}>
            {tagId ? "Edit tag" : "Create tag"}
          </h1>
        </Row>
        <div className='site-layout-content'>
          <Spin spinning={tagSaving} tip='Tag saving...'>
            <Form
              layout='vertical'
              name='tag_edition'
              initialValues={{
                ...initialTranslations,
                parent: innerTag?.parent?.uid,
              }}
              onFinish={handleEdition}
            >
              <Row
                style={{
                  paddingBottom: "20px",
                  borderBottom: "1px solid #d9d9d9",
                }}
                justify='space-between'
              >
                <Col span={12}>
                  <h3 style={{ marginBottom: "40px" }}>Tag</h3>
                  {languageOptions.map(({ value, label }) => (
                    <Form.Item
                      key={value}
                      label={label}
                      name={["translations", value]}
                      required={true}
                    >
                      <Input placeholder={"Tag name"} />
                    </Form.Item>
                  ))}
                  <Form.Item
                    key={"parent"}
                    label={"Parent tag uid"}
                    name={"parent"}
                  >
                    <Select style={{ width: "80%", marginRight: "20px" }}>
                      {innerTagList.map((tag: ITag) => (
                        <Select.Option key={tag.uid} value={tag.uid}>
                          {tag.uid}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {innerTagListFromParent.length ? (
                <>
                  <Row>
                    <h3
                      style={{
                        fontWeight: 700,
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                    >
                      Associated Tags
                    </h3>
                  </Row>
                  <Row>
                    <ul>
                      {innerTagListFromParent.map((tag: ITag) => (
                        <li>
                          <a href={`/reference-data/tag/${tag.uid}`}>
                            {tag.translations.en}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Row>
                </>
              ) : null}
              <Row justify='end'>
                <Form.Item style={{ marginBottom: "0px" }}>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='journal-form-button'
                    style={{ marginTop: "20px" }}
                  >
                    {tagId ? "Save tag" : "Create tag"}
                  </Button>
                  {tagId ? (
                    <Button
                      danger
                      style={{
                        marginLeft: "20px",
                      }}
                      onClick={handleDeletion}
                    >
                      Delete tag
                    </Button>
                  ) : null}
                </Form.Item>
              </Row>
            </Form>
          </Spin>
        </div>
      </Layout.Content>
    </Layout>
  );
};
