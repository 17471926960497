import { useContext, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Modal,
  notification,
  Select,
  Spin,
  Tag,
} from "antd";
import { GlobalContext } from "~/context/global.context";
import { IUser } from "~/model";
import { Flex } from "~/components/shared/global";
import { PlusOutlined } from "@ant-design/icons";
import { searchFilterOptions } from "~/utils/helpers";
import { associateUserToRoom, dissociateUserFromRoom } from "~/services";

type InnerProps = {
  user: IUser;
};
const UserRoomsAssociator = ({ user }: InnerProps) => {
  const { roomList } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [innerRooms, setInnerRooms] = useState<string[]>(
    user?.rooms as string[]
  );

  const fetchAssociateToRoom = async () => {
    const selectedRoom = form.getFieldValue("room");

    try {
      if (!user) throw new Error("User is required");
      if (!selectedRoom) throw new Error("Room is required");

      await associateUserToRoom({
        roomId: selectedRoom,
        userId: user._id,
      });

      setInnerRooms([...innerRooms, selectedRoom]);

      notification.success({
        message: "Success",
        description: "User has been associated to the room",
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: (error as Error)?.message || "An error occurred",
      });
    }
  };

  const fetchDissociateFromRoom = async (roomId: string) => {
    try {
      if (!user) throw new Error("User is required");

      await dissociateUserFromRoom({
        roomId,
        userId: user._id,
      });

      setInnerRooms(innerRooms.filter((r) => r !== roomId));

      notification.success({
        message: "Success",
        description: "User has been dissociated from the room",
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: (error as Error)?.message || "An error occurred",
      });
    }
  };

  const handleAddRoom = async () => {
    Modal.confirm({
      icon: null,
      title: (
        <div>
          {"Add"}{" "}
          <b>
            {user?.firstname} {user?.lastname}
          </b>{" "}
          {"to a room"}
        </div>
      ),
      content: (
        <Form form={form} layout='vertical' onFinish={fetchAssociateToRoom}>
          <Form.Item name='room'>
            <Select
              allowClear
              showSearch
              placeholder='Select a room'
              options={roomList
                ?.filter((r) => !(user?.rooms as string[])?.includes(r.id))
                .map((room) => ({
                  label: room.name,
                  value: room.id,
                }))}
              filterOption={searchFilterOptions}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
      ),
      okText: "Add",
      onOk: () => {
        form.submit();
      },
    });
  };

  const handleRemoveRoom = async (roomId: string) => {
    const roomName = roomList?.find((room) => room.id === roomId)?.name;
    Modal.confirm({
      title: (
        <div>
          {"Are you sure you want to remove"}
          <b>{` ${user?.firstname} ${user?.lastname} `}</b>
          {`from ${roomName}`}
        </div>
      ),
      okText: "Remove",
      onOk: async () => {
        await fetchDissociateFromRoom(roomId);
      },
    });
  };

  if (!roomList) return <Spin />;

  return (
    <Flex align='center'>
      <div>
        {innerRooms?.map((roomId) => (
          <Tag
            key={roomId}
            color='blue'
            closable
            onClose={(e) => {
              e.preventDefault();
              handleRemoveRoom(roomId);
            }}
          >
            {roomList.find((room) => room.id === roomId)?.name}
          </Tag>
        ))}
      </div>
      <Divider type='vertical' />
      <Button
        size='small'
        icon={<PlusOutlined />}
        type='dashed'
        onClick={handleAddRoom}
      >
        {"Add"}
      </Button>
    </Flex>
  );
};

export default UserRoomsAssociator;
